<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Classifiez les liaisons suivantes en fonction de leurs polarités relatives :
      </p>

      <drag-to-rank-input-fr
        v-model="inputs.relativePolarities"
        :items="items"
        class="mb-0"
        style="max-width: 430px"
        prepend-text="Le plus polaire"
        append-text="Le moins polaire"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInputFr from '@/tasks/components/inputs/DragToRankInputFr.vue';

export default {
  name: 'Question493',
  components: {
    DragToRankInputFr,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        relativePolarities: [],
      },
      items: [
        '$\\text{C}-\\text{O}$',
        '$\\text{Cl}-\\text{C}$',
        '$\\text{H}-\\text{N}$',
        '$\\text{Si}-\\text{H}$',
      ],
    };
  },
};
</script>
